<template>
  <div class="layout">
    <div v-if="showSidebar" class="sidebar">
      <router-view name="sidebar" />
    </div>
    <div :class="['main-content', { 'full-width': !showSidebar }]">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    showSidebar() {
      return !['/login', '/logout'].includes(this.$route.path);
    }
  }
}
</script>

<style>
@import '@/assets/fonts.css';

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.btn-vdw {
  background-color: #46c6ff !important;
  border-color: #ffffff !important;
}

.btn-vdw:hover {
  background-color: #00b3e6 !important;
}

.btn-vdw-alternate {
  background-color: #fc4906 !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.btn-vdw-alternate:hover {
  background-color: #d13f06 !important;
}

.btn-vdw-tertiary {
  background-color: #8958fe !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.btn-vdw-tertiary:hover {
  background-color: #6f3fd1 !important;
}

.btn-vdw-blank {
  background-color: #13264d !important;
  border-color: #13264d !important;
  color: #ffffff !important;
  margin-left: 10px;
  margin-right: 10px;
}

.btn-vdw-blank:hover {
  background-color: #f2f2f2 !important;
  color: #13264d !important;
}

.btn-vdw-dropdown {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #13264d !important;
  width: 275px;
}

.btn-vdw-dropdown:hover {
  background-color: #f2f2f2 !important;
}

.icon-img {
  width: 50px;
  height: 50px;
}

.right-padding {
  padding-right: 50px;
}

.left-padding {
  padding-left: 50px;
}

.top-padding {
  padding-top: 50px;
}

.bottom-padding {
  padding-bottom: 50px;
}

.layout {
  display: flex;
}

.sidebar {
  width: 300px;
}

.main-content {
  flex-grow: 1;
}

.full-width {
  width: 100%;
}
</style>
