<template>
  <div class="sidebar d-flex flex-column">
    <ul class="nav flex-column flex-grow-1">
      <li class="nav-item" v-for="item in topMenuItems" :key="item.name">
        <button class="btn btn-primary btn-vdw-sidebar w-100 d-flex align-items-center" @click="navigate(item.link)">
          <div class="icon-wrapper">
            <img v-if="item.icon !== ''" :src="getImg(item.icon)" alt="item.icon" class="image-size">
            <i v-else :class="item.font_awesome" class="icon-size"></i>
          </div>
          <span class="ms-3 text-size"><b>{{ item.name }}</b></span>
        </button>
      </li>
    </ul>
    <ul class="nav flex-column">
      <li class="nav-item" v-for="item in bottomMenuItems" :key="item.name">
        <button class="btn btn-primary btn-vdw-sidebar w-100 d-flex align-items-center" @click="navigate(item.link)">
          <div class="icon-wrapper">
            <img v-if="item.icon !== ''" :src="getImg(item.icon)" alt="item.icon" class="image-size">
            <i v-else :class="item.font_awesome" class="icon-size"></i>
          </div>
          <span class="ms-3 text-size"><b>{{ item.name }}</b></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TheSidebar',
  data() {
    return {
      bottomMenuItems: [
        { name: 'Profile', link: 'Profile', icon: '', font_awesome: 'fa-regular fa-user'},
        { name: 'Account', link: 'Account', icon: '', font_awesome: 'fa-solid fa-gears'},
        { name: 'Log Out', link: 'Logout', icon: '', font_awesome: 'fa-solid fa-arrow-right-from-bracket'}
      ],
      topMenuItems: [
        { name: 'Dashboard', link: 'Dashboard', icon: 'dashboard.png' },
        { name: 'My Tickets', link: 'MyTickets', icon: '', font_awesome: 'fa-solid fa-envelope-open'},
        { name: 'Create Ticket', link: 'CreateTicket', icon: '', font_awesome: 'fa-solid fa-plus-square' },
        { name: 'Ticket Queues', link: 'TicketQueues', icon: '', font_awesome: 'fa-solid fa-folder-tree' },
        { name: 'Queue Management', link: 'QueueManagement', icon: '', font_awesome: 'fa-solid fa-list-check'},
        { name: 'Workflows', link: 'Workflows', icon: '', font_awesome: 'fa-solid fa-briefcase' },
        { name: 'Reports', link: 'Reports', icon: '', font_awesome: 'fa-solid fa-file-lines' },
      ]
    }
  },
  methods: {
    getImg(icon) {
      return require(`@/assets/${icon}`);
    },
    navigate(link) {
      this.$router.push({name: link});
    }
  }
}
</script>

<style scoped>
.icon-size {
  font-size: 35px;
}

.image-size {
    width: 30px;
    height: 30px;
}

.sidebar {
  width: 300px;
  background-color: #13264d;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-vdw-sidebar {
  background-color: #13264d !important;
  border-color: #13264d !important;
  height: 60px;
  text-align: left;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #FC4906 !important;
  }    
}

.icon-wrapper {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-size {
  font-size: 1.2rem;
}
</style>
