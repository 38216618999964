<template>
    <div class="container-fluid min-vh-100 d-flex flex-column">
        <div v-if="loading" class="alert alert-info mt-3" role="alert">
            <div class="d-flex align-items-center">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading queues...
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center my-5">
            <h1>Create a New Ticket</h1>
        </div>
        <div class="row flex-grow-1 custom-padding">
            <div class="col custom-border p-3">
                <label for="queueSelector">Select Queue:</label>
                <select id="queueSelector" class="form-select" v-model="selectedQueue" @change="onQueueChange" multiple>
                    <option v-if="loading" disabled>Loading queues...</option>
                    <option v-for="queue in queues" :key="queue.queue_id" :value="queue.queue_id">{{ queue.queue_name }}</option>
                </select>
            </div>
            <div class="col custom-border p-3 ms-3">
                <h4>Attributes</h4>
                <div v-if="queueData">
                    <div v-for="attribute in queueData.attributes || []" :key="attribute.key" class="mb-3">
                        <label :for="attribute.key">{{ attribute.key }}</label>
                        <input
                            :id="attribute.key"
                            class="form-control"
                            v-model="formData.attributes[attribute.key]"
                            :required="attribute.required"
                        />
                        <div v-if="attribute.required" class="text-danger" v-show="!formData.attributes[attribute.key]">This attribute is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3 flex-grow-1 custom-border custom-padding p-3">
            <label for="comment">Ticket Information</label>
            <textarea id="comment" class="form-control" v-model="formData.message" placeholder="Begin writing your ticket here..." required></textarea>
            <div class="text-danger" v-show="!formData.message">This field is required</div>
        </div>
        <button class="btn btn-primary align-self-center" :disabled="!formIsValid" @click="createTicket">Submit</button>
    </div>
</template>

<script>
import axios from 'axios';
import VueCookie from 'vue-cookie';

export default {
    name: 'CreateTicketView',
    data() {
        return {
            queues: [],
            selectedQueue: [],  // Initialize as an array
            queueData: null,
            formData: {
                attributes: {},  // Initialize attributes as an object
                message: ''
            },
            loading: false,
            error: false,
        };
    },
    computed: {
        formIsValid() {
            if (!this.formData.message) {
                return false;
            }
            if (this.queueData && this.queueData.attributes) {
                for (const attribute of this.queueData.attributes) {
                    if (attribute.required && !this.formData.attributes[attribute.key]) {
                        return false;
                    }
                }
            }
            return true;
        },
    },
    methods: {
        async getQueues() {
            this.loading = true;
            var more = true;
            var next_key = 0;
            const token = VueCookie.get('JWT');
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            var local_queues = [];
            var local_queue_count = 0;

            while (more) {
                try {
                    const url = process.env.VUE_APP_API_BASE_URL + '/tickets/queue?key=' + next_key;
                    const response = await axios.get(url, { headers })
                    if (response.status !== 200) {
                        more = false;
                        this.loading = false; 
                        return;
                    }

                    for (const queue of response.data.queues) {
                        local_queues.push(queue);
                        local_queue_count++;
                    }
                    if (response.data.next_key === 0) {
                        more = false;
                    } else {
                        next_key = response.data.next_key;
                    }
                } catch (error) {
                    console.error(error);
                    more = false;
                    this.loading = false;  // Set loading to false
                    return;
                }
            }
            this.queue_count = local_queue_count;
            this.queues = local_queues;
            this.filteredQueues = this.queues;
            this.loading = false;
        },
        async getQueue() {
            const token = VueCookie.get('JWT');
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            axios.get(process.env.VUE_APP_API_BASE_URL + '/tickets/queue/' + this.selectedQueue, { headers })
                .then((response) => {
                    this.queueData = response.data;
                    this.loading = false;
                    this.initializeFormData();
                })
                .catch((error) => {
                    console.error(error);
                    this.error = true;
                });
        },
        onQueueChange() {
            if (this.selectedQueue) {
                this.getQueue();
            }
        },
        initializeFormData() {
            this.formData = {
                attributes: {},  // Ensure attributes is an object
                message: ''
            };
            if (this.queueData && this.queueData.attributes) {
                for (const attribute of this.queueData.attributes) {
                    this.formData.attributes[attribute.key] = '';
                }
            }
        },
        createTicket() {
            console.log(this.formData);
        },
    },
    mounted() {
        this.getQueues();
    },
};
</script>

<style scoped>
.custom-border {
  border: 5px solid #13264D !important;
  border-radius: 40px;
}

.custom-padding {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
}

button.btn {
  width: auto;
  margin-bottom: 50px;
}
</style>
