<template>
    <div class="container-fluid min-vh-100 d-flex flex-column">
        <div v-if="loading" class="alert alert-info mt-3" role="alert">
            <div class="d-flex align-items-center">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading queues...
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center my-5">
            <h1>Queue Management</h1>
            <button @click="openModel" class="btn btn-primary btn-vdw-alternate">+ Add Queue</button>
        </div>
        <div class="row flex-grow-1">
            <queue-management-filter class="col-3 custom-padding" v-model="filterText"/>
            <queue-list class="col-8 custom-padding" :queues="filteredQueues"/>
        </div>
        <queue-model :show="showModal" @close="closeModal" @submit="createNewQueue"/>
    </div>
</template>

<script>
import QueueList from '@/components/QueueManagement/QueueList.vue';
import QueueManagementFilter from '@/components/QueueManagement/QueueManagementFilter.vue';
import QueueModel from '@/components/QueueManagement/QueueModel.vue';

import axios from 'axios';
import VueCookie from 'vue-cookie';

export default {
    name: 'QueueManagementView',
    components: {
        QueueList,
        QueueManagementFilter,
        QueueModel
    },
    data() {
        return {
            isSubmitting: false,
            loading: false,  // Added loading state
            queue_count: 0,
            queues: [],
            filteredQueues: [],
            filterText: '',
            showModal: false,
        }
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
        openModel() {
            this.showModal = true;
        },
        createNewQueue(formData) {
            if (this.isSubmitting) {
                return;
            }
            this.isSubmitting = true;
            this.loading = true;  // Set loading to true
            
            const token = VueCookie.get('JWT');
            const headers = {
                'Authorization': 'Bearer ' + token
            };
            
            axios.post(process.env.VUE_APP_API_BASE_URL + '/tickets/queue', formData, { headers })
                .then(() => {
                    this.isSubmitting = false;
                    this.loading = false;  // Set loading to false
                    this.getQueues();
                })
                .catch((error) => {
                    this.isSubmitting = false;
                    this.loading = false;  // Set loading to false
                    console.error(error);
                });
        },
        async getQueues() {
            this.loading = true;  // Set loading to true
            var more = true;
            var next_key = 0;
            const token = VueCookie.get('JWT');
            const headers = {
                'Authorization': 'Bearer ' + token
            };

            var local_queues = [];
            var local_queue_count = 0;

            while (more) {
                try {
                    const url = process.env.VUE_APP_API_BASE_URL + '/tickets/queue?key=' + next_key;
                    const response = await axios.get(url, { headers })
                    if (response.status !== 200) {
                        more = false;
                        this.loading = false; 
                        return;
                    }

                    for (const queue of response.data.queues) {
                        local_queues.push(queue);
                        local_queue_count++;
                    }
                    if (response.data.next_key === 0) {
                        more = false;
                    } else {
                        next_key = response.data.next_key;
                    }
                } catch (error) {
                    console.error(error);
                    more = false;
                    this.loading = false;  // Set loading to false
                    return;
                }
            }
            this.queue_count = local_queue_count;
            this.queues = local_queues;
            this.filteredQueues = this.queues;
            this.loading = false;  // Set loading to false
        }
    },
    watch: {
        filterText(newFilterText) {
            const filterTextLower = newFilterText.toLowerCase();
            this.filteredQueues = this.queues.filter(queue => queue.queue_name.toLowerCase().includes(filterTextLower));
        }
    },
    created() {
        this.getQueues();
    }
}
</script>

<style scoped>
.custom-padding {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
}
</style>
