<template>
  <div class="container-fluid min-vh-100 d-flex flex-column">
    <div v-if="loading" class="alert alert-info mt-3" role="alert">
      <div class="d-flex align-items-center">
        <div class="spinner-border spinner-border-sm me-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Loading queue information...
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center my-5">
      <h1>{{ queue_data.queue_name }}</h1>
      <button @click="openEditModal" class="btn btn-primary btn-vdw-alternate">Edit Queue</button>
    </div>
    <div class="row flex-grow-1">
      <div v-if="error" class="alert alert-danger" role="alert">
        <strong>Error:</strong> Unable to fetch queue information.
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div>
          <h4>
            <strong>Ticket Prefix:</strong><br>
            {{ queue_data.ticket_prefix }}
          </h4>
        </div>
        <div>
          <h4>
            <strong>SLA Hours:</strong><br>
            {{ queue_data.sla_hours }}
          </h4>
        </div>
        <div>
          <h4>
            <strong>Last Updated At:</strong><br>
            {{ queue_data.last_updated_at }}
          </h4>
        </div>
        <div>
          <h4>
            <strong>Created At:</strong><br>
            {{ queue_data.created_at }}
          </h4>
        </div>
      </div>
      <div class="row flex-grow-1 mt-3">
        <div class="col p-3 custom-border">
          <h3>Description:</h3>
          <p>{{ queue_data.description }}</p>
        </div>
        <div class="col p-3 custom-border">
          <h3>Attributes:</h3>
          <table class="table">
            <thead>
              <tr>
                <th>Attribute Name</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attribute in queue_data.attributes" :key="attribute.key">
                <td>{{ attribute.key }}</td>
                <td>{{ attribute.required ? 'Yes' : 'No' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="alert alert-danger mt-5" role="alert" style="width: 100%;">
      <strong>Danger Zone</strong> - This action is not reversible.<br>
      <button @click="openDeleteModal" class="btn btn-danger">Delete Queue</button>
    </div>
    <queue-edit-modal :show="showEditModal" :initialData="queue_data" @close="closeEditModal" @update="editQueue"></queue-edit-modal>
    <queue-delete-modal :show="showDeleteModal" :queueName="queue_data.queue_name" @close="closeDeleteModal" @delete-confirmed="deleteQueue"></queue-delete-modal>
  </div>
</template>

<script>
import axios from 'axios';
import VueCookie from 'vue-cookie';
import QueueDeleteModal from '@/components/QueueManagement/QueueDeleteModal.vue';
import QueueEditModal from '@/components/QueueManagement/QueueEditModal.vue';

export default {
  name: 'QueueView',
  components: {
    QueueDeleteModal,
    QueueEditModal
  },
  created() {
    this.getQueue();
  },
  data() {
    return {
      error: false,
      loading: true,
      queue_id: this.$route.params.queue_id,
      queue_data: {
        attributes: [],
        created_at: '',
        description: '',
        last_updated_at: '',
        queue_name: '',
        sla_hours: 0,
        ticket_prefix: ''
      },
      showEditModal: false,
      showDeleteModal: false
    };
  },
  methods: {
    async getQueue() {
      const token = VueCookie.get('JWT');
      const headers = {
        'Authorization': 'Bearer ' + token
      };

      axios.get(process.env.VUE_APP_API_BASE_URL + '/tickets/queue/' + this.queue_id, { headers })
        .then((response) => {
          this.queue_data = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.error = true;
        });
    },
    openEditModal() {
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    openDeleteModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    editQueue(updatedData) {
      if (!updatedData.queue_id) {
        updatedData.queue_id = this.queue_id;
      }

      const token = VueCookie.get('JWT');
      const headers = {
        'Authorization': 'Bearer ' + token
      };

      axios.put(process.env.VUE_APP_API_BASE_URL + '/tickets/queue/' + this.queue_id, updatedData, { headers })
        .then(() => {
          this.loading = true;
          this.getQueue();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteQueue() {
      const token = VueCookie.get('JWT');
      const headers = {
        'Authorization': 'Bearer ' + token
      };

      axios.delete(process.env.VUE_APP_API_BASE_URL + '/tickets/queue/' + this.queue_id, { headers })
        .then(() => {
          this.$router.push({ component: 'QueueManagement' });
        })
        .catch((error) => {
          console.error(error);
          // Handle the error (e.g., show an error message)
        })
        .finally(() => {
          this.closeDeleteModal();
        });
    },
    updateTime() {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      this.queue_data.last_updated_at = new Date(this.queue_data.last_updated_at + ' UTC').toLocaleString(undefined, options);
      this.queue_data.created_at = new Date(this.queue_data.created_at + ' UTC').toLocaleString(undefined, options);
    }
  },
  watch: {
    queue_data() {
      this.updateTime();
    }
  }
};
</script>

<style scoped>
.custom-border {
  border: 5px solid #13264D !important;
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 30px;
  margin-bottom: 50px;
}
</style>
