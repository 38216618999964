import { createStore } from 'vuex'

const store = createStore({
  state: {
    counter: 0,
    loadedUserData: false,
    loggedIn: false,
    userData: {
      accountName: '',
      accountID: '',
      email: '',
      firstName: '',
      fullName: '',
      groupName: '',
      lastName: '',
      userID: '',
      permissions: {
        accountManagement: false,
        billingManagement: false,
        ticketCreation: false,
        groupManagement: false,
        ticketQueueMangement: false,
        ticketReports: false,
        ticketWorkflowManagement: false,
      }
    }
  },
  getters: {
    counter: state => state.counter,
    loggedIn: state => state.loggedIn,
    loadedUserData: state => state.loadedUserData,
    userData: state => state.userData
  },
  mutations: {
    incrementCounter(state) {
      state.counter++;
    },
    setCounterToZero(state) {
      state.counter = 0;
    },
    setLoggedInFalse(state) {
      state.loggedIn = false;
    },
    setLoggedInTrue(state) {
      state.loggedIn = true;
    },
    setLoadedUserDataFalse(state) {
      state.loadedUserData = false;
    },
    setLoadedUserDataTrue(state) {
      state.loadedUserData = true;
    },
    setUserInfo(state, attributes) {
      state.userData.accountName = attributes.user.AccountName;
      state.userData.accountID = attributes.user.AccountID;
      state.userData.email = attributes.user.Email;
      state.userData.firstName = attributes.user.FirstName;
      state.userData.groupName = attributes.user.GroupName;
      state.userData.lastName = attributes.user.LastName;
      state.userData.userID = attributes.user.UserID;
      state.userData.permissions.accountManagement = attributes.user.AccountManagement;
      state.userData.permissions.billingManagement = attributes.user.BillingManagement;
      state.userData.permissions.ticketCreation = attributes.user.TicketCreation;
      state.userData.permissions.groupManagement = attributes.user.GroupManagement;
      state.userData.permissions.ticketQueueMangement = attributes.user.TicketQueueManagement;
      state.userData.permissions.ticketReports = attributes.user.TicketReports;
      state.userData.permissions.ticketWorkflowManagement = attributes.user.TicketWorkflowManagement;
      state.loadedUserData = true;
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;
