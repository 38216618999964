<template>
    <div class="logout-container">
      <div class="logout-card">
        <img :src="getImg()" alt="Logo" class="logo">
        <h4 class="logout-text">Have a great day and we'll see you next time</h4>
        <button @click="sendToLogin()" class="btn btn-primary btn-vdw-alternate w-100">Return to Log In</button>
      </div>
    </div>
</template>

<script>
import VueCookie from 'vue-cookie';

export default {
    name: 'LogoutView',
    beforeCreate() {
        this.$store.commit('setLoggedInFalse');
        VueCookie.delete('JWT');
    },
    methods: {
        getImg() {
            return require(`@/assets/logo-medium.png`);
        },
        sendToLogin() {
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped>
.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #13264d;
}

.logout-card {
  background: #13264d;
  padding: 2rem;
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.logout-text {
  color: #ffffff;
  margin-bottom: 20px;
}

.logo {
  width: 100%;
  height: auto;
  margin-bottom: 100px;
}
</style>