<template>
  <div class="login-container">
    <div class="login-card">
      <img :src="getImg()" alt="Logo" class="logo">
      <form @submit.prevent="login()">
        <div class="mb-3">
          <input type="email" class="form-control" id="email" placeholder="Email" v-model="email">
        </div>
        <div class="mb-3">
          <input type="password" class="form-control" id="password" placeholder="Password" v-model="password">
        </div>
        <button type="submit" class="btn btn-primary btn-vdw-alternate w-100">Log in</button>
        <div v-if="error" class="alert alert-danger mt-3" role="alert">
          Invalid email or password
        </div>
        <div v-if="loading" class="alert alert-info mt-3" role="alert">
          <div class="d-flex align-items-center">
            <div class="spinner-border spinner-border-sm me-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            Trying to log you in...
          </div>
        </div>
        <div class="links">
          <router-link to="/forgot-password" class="forgot-password">Forgot password?</router-link>
          <router-link :to="getRegisterLink()" class="sign-up">Don't have an account?<br>Sign up for a free trial here</router-link>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import { CheckAndSetState } from '@/scripts/helper.js';
import axios from 'axios';
import VueCookie from 'vue-cookie';

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      error: false,
      loading: false
    }
  },
  methods: {
    getImg() {
      return require(`@/assets/logo-medium.png`);
    },
    getRegisterLink() {
      return process.env.VUE_APP_REGISTER_LINK;
    },
    login() {
      this.error = false;
      this.loading = true;
      axios.post(process.env.VUE_APP_API_BASE_URL + '/user/login', {
        email: this.email,
        password: this.password
      })
      .then(response => {
        this.loading = false;     
        if (response.status === 200) {
          VueCookie.set('JWT', response.data, { expires: '1D' });
          CheckAndSetState();
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.error = true;
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
        this.error = true;
      });
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #13264d;
}

.login-card {
  background: #13264d;
  padding: 2rem;
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.logo {
  width: 100%;
  height: auto;
  margin-bottom: 100px;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.forgot-password,
.sign-up {
  color: #46C6FF;
  text-decoration: none;
  margin: 0.5rem 0;
}

.or-login-with {
  margin: 1rem 0;
  font-weight: bold;
}
</style>
