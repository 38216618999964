<template>
  <transition name="modal">
    <div v-if="show" class="modal-overlay" @click="closeModal">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content p-4">
          <form @submit.prevent="submitForm">
            <h2 class="modal-title">Add Queue</h2>
            <div class="mb-3">
              <label for="name" class="form-label">Queue Name</label>
              <input type="text" class="form-control" id="name" v-model="formData.queue_name" placeholder="IT Help Request...">
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="sla" class="form-label">Service Level Agreement (SLA)</label>
                <input type="number" class="form-control" id="sla" v-model="formData.sla" placeholder="24">
                <label for="sla" class="form-label">Set 0 for no SLA</label>
              </div>
              <div class="col">
                <label for="ticketPrefix" class="form-label">Ticket Prefix</label>
                <input type="text" class="form-control" id="ticketPrefix" v-model="formData.ticket_prefix" placeholder="Prefix">
                <div v-if="ticketPrefixError" class="text-danger">{{ ticketPrefixError }}</div>
              </div>
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea class="form-control" id="description" v-model="formData.description" rows="3" placeholder="A brief description of the queue..."></textarea>
            </div>
            <h2 class="modal-title">Attributes</h2>
            <div class="row mb-3">
              <div class="col-10">
                <label for="key" class="form-label">Attribute Name</label>
                <input type="text" class="form-control" v-model="newAttribute.key">
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                <div class="text-center w-100">
                  <label for="required" class="form-label">Required?</label>
                  <input type="checkbox" class="form-check-input custom-checkbox" v-model="newAttribute.required">
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-vdw mb-3" @click="addAttribute">Add Attribute</button>
            <ul class="list-group mb-3">
              <li v-for="(attribute, index) in formData.attributes" :key="index" class="list-group-item">
                <strong>Name:</strong> {{ attribute.key }} | <strong>Required:</strong> {{ attribute.required ? 'Yes' : 'No' }}
              </li>
            </ul>
            <button type="submit" class="btn btn-primary btn-vdw-alternate" :disabled="!isFormValid">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'QueueModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      formData: {
        attributes: [],
        description: '',
        queue_name: '',
        sla: 24,
        ticket_prefix: ''
      },
      newAttribute: {
        key: '',
        required: false
      },
    };
  },
  computed: {
    ticketPrefixError() {
      const prefix = this.formData.ticket_prefix;
      if (!prefix) return 'Ticket Prefix is required.';
      if (prefix.length > 5) return 'Ticket Prefix cannot be longer than 5 characters.';
      if (!/^[a-z]+$/.test(prefix)) return 'Ticket Prefix can only contain lowercase letters.';
      return '';
    },
    isFormValid() {
      return !this.ticketPrefixError;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetFormData(); // Reset form data when modal is closed
    },
    submitForm() {
      if (this.isFormValid) {
        this.$emit('submit', this.formData);
        this.closeModal();
      }
    },
    addAttribute() {
      if (this.newAttribute.key) {
        this.formData.attributes.push({ ...this.newAttribute });
        this.newAttribute.key = '';
        this.newAttribute.required = false;
      }
    },
    resetFormData() {
      this.formData = {
        attributes: [],
        description: '',
        queue_name: '',
        sla: 24,
        ticket_prefix: ''
      };
      this.newAttribute = {
        key: '',
        required: false
      };
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; 
}

.modal-dialog {
  width: 600px;
  margin-top: 20px;
}

.modal-content {
  background: #13264D;
  border-radius: 10px;
  color: #ffffff;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Custom checkbox styles */
.custom-checkbox:checked {
  background-color: #FC4906;
  border-color: #FC4906;
}

/* Custom checkbox appearance */
.custom-checkbox:checked::before {
  background-color: #007bff;
}

/* Modal transition styles */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-dialog,
.modal-leave-active .modal-dialog {
  transform: translateY(0);
}
</style>
