// Import Vue items
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.js';
import store from '@/store/index.js';

// Import Google Analytics
import VueGtag from 'vue-gtag';

// Import Bootstrap
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css';

// Install Font Awesome
import '@fortawesome/fontawesome-free/css/all.css';


const app = createApp(App);
app.use(store);
app.use(router);

// Google Analytics
app.use(
    VueGtag,
    {
        appName: 'VanderwallTech',
        pageTrackerScreenviewEnabled: true,
        config: { 
            id: 'G-SXC7E7ZL41' 
        }
    },
    router
);

app.mount('#app');
