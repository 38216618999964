<!-- QueueList.vue -->
<template>
  <div class="queue-list container p-3 custom-border d-flex flex-column">
    <div class="header d-flex justify-content-between align-items-center py-3">
      <h3>{{ queues.length }} queues</h3>
      <div>
        <select class="form-select" v-model="selectedPageSize">
          <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
        </select>
      </div>
    </div>
    <div class="table-header d-flex p-2 custom-background">
      <div @click="sortBy('prefix')" class="sortable col-2 text-start">Queue Prefix</div>
      <div @click="sortBy('name')" class="sortable col-3 text-start">Queue Name</div>
      <div class="col text-start">Description</div>
    </div>
    <div class="queues">
      <QueueItem v-for="queue in paginatedQueues" :key="queue.id" :queue="queue" />
    </div>
    <div class="paginator d-flex justify-content-center align-items-center py-3 mt-auto">
      <button class="btn btn-primary btn-vdw-alternate me-2" @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button class="btn btn-primary btn-vdw-alternate ms-2" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import QueueItem from './QueueItem.vue';

export default {
  components: { QueueItem },
  props: {
    queues: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedPageSize: 25,
      currentPage: 1,
      sortKey: '',
      sortOrder: 1,
      pageSizes: [25, 50, 100]
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.queues.length / this.selectedPageSize);
    },
    paginatedQueues() {
      let sortedQueues = [...this.queues];
      if (this.sortKey) {
        sortedQueues.sort((a, b) => {
          let result = a[this.sortKey] > b[this.sortKey] ? 1 : -1;
          return result * this.sortOrder;
        });
      }
      const start = (this.currentPage - 1) * this.selectedPageSize;
      const end = start + this.selectedPageSize;
      return sortedQueues.slice(start, end);
    }
  },
  watch: {
    queues() {
      this.currentPage = 1; // Reset to first page on queue update
    }
  },
  methods: {
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
.custom-background {
  background-color: #13264D;
  color: white;
}

.custom-border {
  border: 5px solid #13264D !important;
  border-radius: 40px;
}

.sortable {
  cursor: pointer;
}
</style>
