// Import Vue items
import { createRouter, createWebHistory } from 'vue-router';

// Import helper functions
import { CheckJWT, CheckAndSetState } from '@/scripts/helper.js';

// Import store
import store from '@/store/index.js';

// Import views
import CreateTicket from '@/views/CreateTicketView.vue';
import DashboardView from '@/views/DashboardView.vue';
import LoginView from '@/views/LoginView.vue';
import LogoutView from '@/views/LogoutView.vue';
import QueueView from '@/views/QueueView.vue';
import QueueManagementView from '@/views/QueueManagementView.vue';

// Import Sidebar
import TheSidebar from '@/components/TheSidebar.vue';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes: [
    { 
      path: '/', 
      redirect: '/login'
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      components: {
        default: DashboardView,
        sidebar: TheSidebar
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/logout',
      name: 'Logout',
      component: LogoutView
    },
    {
      path: '/ticket/create',
      name: 'CreateTicket',
      components: {
        default: CreateTicket,
        sidebar: TheSidebar
      }
    },
    {
      path: '/ticket/queues/:queue_id:',
      name: 'QueueView',
      components: {
        default: QueueView,
        sidebar: TheSidebar
      }
    },
    {
      path: '/ticket/queues/manage',
      name: 'QueueManagement',
      components: {
        default: QueueManagementView,
        sidebar: TheSidebar
      }
    },
    { 
      path: '/:catchAll(.*)', 
      redirect: '/login' 
    },
  ],
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
})

// called whenever the router changes
router.beforeEach(function(to, _from, next) {
  if (!store.state.loadedUserData) {
    CheckAndSetState();
  }

  const authenticated = CheckJWT();
  if (authenticated) {
    if (to.name === 'Login') {
        next({ name: 'Dashboard'})
    } else {
        next();
    }
  } else {
    if (to.name === 'Login') {
        next();
    } else {
        next({ name: 'Login' });
    }
  }
});


export default router;
