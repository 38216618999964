<template>
  <transition name="modal">
    <div v-if="show" class="modal-overlay" @click="closeModal">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content p-4">
          <h2 class="modal-title">Confirm Delete</h2>
          <div class="modal-body">
            Are you sure you want to delete {{ queueName }}?
          </div>
          <div class="modal-footer d-flex justify-content-end mt-4">
            <button type="button" class="btn btn-secondary btn-vdw-alternate me-2" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'QueueDeleteModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    queueName: {
      type: String,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmDelete() {
      this.$emit('delete-confirmed');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modal-dialog {
  width: 600px;
  margin-top: 20px;
}

.modal-content {
  background: #13264D;
  border-radius: 10px;
  color: #ffffff;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-footer {
  margin-top: 50px; /* Space between text and buttons */
}

/* Modal transition styles */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-dialog,
.modal-leave-active .modal-dialog {
  transform: translateY(0);
}
</style>
