<template>
    <div>
       <h1>Welcome {{ this.$store.state.userData.firstName }}!</h1>
    </div>
</template>

<script>
export default {
    name: 'DashboardView',
}
</script>

<style></style>