<!-- QueueItem.vue -->
<template>
    <div 
        class="queue-item d-flex border-bottom py-2"
        @click="goToQueue(this.queue.queue_id)"
        @mouseover="hover = true" 
        @mouseleave="hover = false"
        :class="{ 'hover': hover }"
    >
        <div class="col-2">{{ queue.ticket_prefix }}</div>
        <div class="col-3">{{ queue.queue_name }}</div>
        <div class="col">{{ queue.description }}</div>
    </div>
</template>

<script>
export default {
    name: 'QueueItem',
    data() {
        return {
            hover: false
        };
    },
    methods: {
        goToQueue(queue_id) {
            this.$router.push({ name: 'QueueView', params: { queue_id } });
        }
    },
    props: {
        queue: {
            type: Object,
            required: true
        }
    },
}
</script>

<style scoped>
.queue-item.hover {
    background-color: #46C6FF;
    color: #ffffff;
}
</style>
  