import axios from 'axios';
import VueCookie from 'vue-cookie';
import store from '@/store/index.js';


export async function BackendCheckJWT() {
  const jwt = VueCookie.get("JWT");
  if (jwt) {
    const b64attributes = jwt.substring(jwt.indexOf(".") + 1, jwt.lastIndexOf("."));
    const attributes = JSON.parse(atob(b64attributes));
    try {
      const response = await axios.get(process.env.VUE_APP_API_BASE_URL + "/users/" + attributes.userData.userID + "/validate", {
        headers: {
        'Authorization': `Bearer ${jwt}`,
        }
      })
  
      if (response.status === 200) {
        store.commit('setCounterToZero');
        store.commit('setLoggedInTrue');
        return true;
      } else {
        store.commit('setCounterToZero');
        store.commit('setLoggedInFalse');
        return false;
      }
    } catch(error) {
      console.log(error);
      store.commit('setCounterToZero');
      store.commit('setLoggedInFalse');
      return false;
    }
  } else {
    store.commit('setCounterToZero');
    store.commit('setLoggedInFalse');
    return false;
  }
}

export function CheckJWT() {
    const jwt = VueCookie.get("JWT");
    store.commit('incrementCounter');

    if (jwt) {
      if (store.getters.counter >= 10) {
        return BackendCheckJWT()
      }
      const b64attributes = jwt.substring(jwt.indexOf(".") + 1, jwt.lastIndexOf("."));
      const attributes = JSON.parse(atob(b64attributes));
      const currentTime = Math.floor(Date.now() / 1000);
      if (attributes.exp > currentTime) {
        store.commit('setLoggedInTrue');
        return true;
      } else {
        store.commit('setLoggedInFalse');
        return false;
      }
    } else { 
      store.commit('setLoggedInFalse');
      return false;
    }
}

export function CheckAndSetState() {
  if (store.getters.loadedUserData) {
    return;
  }

  if (store.getters.loggedIn) {
    return;
  }

  const jwt = VueCookie.get("JWT");
  if (!jwt) {
    store.commit('setLoggedInFalse');
    store.commit('setLoadedUserDataFalse');
    return;
  }

  const b64attributes = jwt.substring(jwt.indexOf(".") + 1, jwt.lastIndexOf("."));
  const attributes = JSON.parse(atob(b64attributes));
  store.commit('setUserInfo', attributes);
}