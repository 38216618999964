<template>
  <div class="queue-list container p-3 custom-border">
    <h4>Filter</h4>
    <div class="input-container">
      <i class="fa fa-search search-icon"></i>
      <input
        class="w-100 search-input"
        type="text"
        id="nameFilter"
        v-model="filterText"
        placeholder="Search"
      />
    </div>
  </div>
</template>
  
  
<script>
export default {
  name: 'QueueManagementFilter',
  props: ['modelValue'],
  computed: {
    filterText: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('filter-change', value);
      }
    }
  }
}
</script>

  
<style scoped>
.custom-border {
  border: 5px solid #13264D !important;
  border-radius: 40px;
}

.input-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 1.2em;
  color: #aaa;
}

.search-input {
  padding-left: 35px;
  border: 2px solid #ccc;
  border-radius: 4px;
  height: 2.5em;
}

.search-input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px #007BFF;
}

.search-input::placeholder {
  color: #aaa;
}
</style>

  